<template>
  <div>
    <section class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16">
      <div class="md:tw-grid md:tw-grid-cols-2 md:tw-gap-8 tw-text-left">
        <div class="tw-col-1 tw-justify-center tw-flex tw-flex-col">
          <h1 class="tw-font-serif tw-text-red tw-text-4xl sm:tw-text-4rem">Mango & Lola</h1>
          <h2 class="tw-mt-3 tw-font-serif tw-text-gray-n3 tw-text-lg sm:tw-text-2xl">for vendors</h2>
          <ul class="tw-pl-0 tw-mt-3 tw-text-sm">
            <li>Are you tired of paying for marketing that does not bring you any ROI?</li>
            <li>Are you tired of being stuck in a yearly contract that you want to get out of but cannot?</li>
            <li>Would you like a marketplace where you can connect with your ideal clients?</li>
            <li>Would you like a marketplace where you can list your business for free?</li>
            <li>Would you like to have all your bookings, booking requests in one place for free?</li>
            <li>Would you like no monthly contracts?</li>
            <li>Well then Mango & Lola must be for you!</li>
          </ul>
          <p class="tw-my-6 tw-w-3/4 tw-text-black">Listing your professional wedding business as a vendor on Mango &
            Lola is fast, easy and free.</p>
          <router-link
            class="tw-inline-flex tw-no-underline tw-self-start tw-items-center tw-px-14 tw-py-4 tw-border tw-bg-red tw-leading-4 tw-font-semibold tw-rounded-md tw-text-white"
            :to="{ name: 'register' }">
            {{ $t('auth.sign_up') }}
          </router-link>
        </div>
        <div class="tw-col-span-1 tw-mt-4 sm:tw-mt-0">
          <img class="tw-rounded-lg tw-object-cover tw-object-center" src="@/assets/img/landing/vendors/1.jpg" alt="">
        </div>
      </div>
    </section>

    <section class="tw-max-w-9xl tw-py-8 sm:tw-py-20 tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16 tw-mt-8 sm:tw-mt-24">
      <div class="md:tw-grid md:tw-grid-cols-2 md:tw-gap-8 tw-text-left">
        <div class="tw-col-1 tw-mb-6 sm:tw-mb-0">
          <img class="tw-rounded-lg tw-object-cover tw-object-center" src="@/assets/img/landing/vendors/2.jpg" alt="">
        </div>
        <div class="tw-col-1 tw-justify-center tw-flex tw-flex-col">
          <h3 class="tw-font-semibold tw-text-2xl sm:tw-text-2rem tw-text-black tw-leading-relaxed">Free Marketing*</h3>
          <p class="tw-mt-4 tw-mb-3 tw-text-lg sm:tw-text-xl tw-text-black">
            Our user-friendly platform was designed by Wedding Pros for Wedding Pros!
          </p>
          <p class="tw-mb-0 tw-text-lg sm:tw-text-xl tw-text-black">
            Once your listing is approved, you can showcase your best work with photos and reviews so you can be
            discovered and booked by newly engaged couples from around the world.
          </p>

        </div>
      </div>
    </section>

    <section class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16">
      <div class="md:tw-grid md:tw-grid-cols-2 md:tw-gap-8 tw-text-left">
        <div class="tw-col-1 tw-justify-center tw-flex tw-flex-col">
          <h3 class="tw-font-semibold tw-text-2xl sm:tw-text-2rem tw-text-black">Features</h3>
          <p class="tw-my-6 tw-text-black tw-text-lg sm:tw-text-xl tw-font-semibold">
            We stand apart from other sites by enabling you to:
          </p>
          <ul class="tw-list-none tw-pl-0 tw-space-y-6 tw-text-black">
            <li class="tw-flex tw-items-center">
              <check-circle-icon class="tw-w-5 tw-h-5 tw-mr-4 tw-text-red" />
              Advertise your Company for Free
            </li>
            <li class="tw-flex tw-items-center">
              <check-circle-icon class="tw-w-5 tw-h-5 tw-mr-4 tw-text-red" />
              Showcase your Best Work
            </li>
            <li class="tw-flex tw-items-center">
              <check-circle-icon class="tw-w-5 tw-h-5 tw-mr-4 tw-text-red" />
              List your Offerings in a Simple Way
            </li>
            <li class="tw-flex tw-items-center">
              <check-circle-icon class="tw-w-5 tw-h-5 tw-mr-4 tw-text-red" />
              Easily Manage your Rates and Availability
            </li>
            <li class="tw-flex tw-items-center">
              <check-circle-icon class="tw-w-5 tw-h-5 tw-mr-4 tw-text-red" />
              Get booked on Mango & Lola
            </li>
            <li class="tw-flex tw-items-center">
              <check-circle-icon class="tw-w-5 tw-h-5 tw-mr-4 tw-text-red" />
              Stay up to date with your Reservations
            </li>
            <li class="tw-flex tw-items-center">
              <check-circle-icon class="tw-w-5 tw-h-5 tw-mr-4 tw-text-red" />
              Process Customer Invoices and Payments
            </li>
          </ul>
        </div>
        <div class="tw-col-1 tw-mt-6 sm:tw-mt-0">
          <img class="tw-rounded-lg tw-object-cover tw-object-center" src="@/assets/img/landing/vendors/4.jpg" alt="">
        </div>
      </div>
    </section>

    <section class="tw-bg-red-100 tw-text-left tw-py-16 tw-text-black tw-mt-8 sm:tw-mt-20">
      <div class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16">
        <h3 class="tw-font-semibold tw-text-2xl sm:tw-text-2rem tw-mb-4 tw-leading-relaxed">Benefits</h3>
        <div class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-6 tw-gap-x-6 tw-gap-y-7">
          <benefit-box class="tw-col-span-2" text="Your listing with all its features is free to you">
            <gift-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2" text="Optimize your revenue">
            <activity-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2" text="Create custom quotes">
            <edit2-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2" text="Send automatic payment reminders">
            <dollar-sign-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2" text="Clients sign and pay directly from within platform">
            <log-in-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2" text="Message clients directly from the platform">
            <mail-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2" text="Access to data">
            <database-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2" text="Improve your performance">
            <arrow-up-right-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
          <benefit-box class="tw-col-span-2" text="Enhance your partnership with other vendors">
            <smile-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
          </benefit-box>
        </div>
      </div>
    </section>

    <section class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16 tw-py-20">
      <div class="md:tw-grid md:tw-grid-cols-8 md:tw-gap-x-8 ">
        <div class="tw-col-span-6">
          <h3 class="tw-font-semibold tw-text-2xl sm:tw-text-2rem tw-leading-relaxed tw-mb-4">So Let’s Get Started!</h3>
          <p class="tw-mb-3 tw-leading-relaxed">
            Your time is limited, so we are here to help you build and grow your online business with the right tools.
            Like you, we are passionate about weddings and bringing newly engaged couples right to your business.
          </p>
          <p class="tw-mb-3 tw-leading-relaxed">
            Our platform helps narrow down their search, share information and photos about your company, and provide
            the information clients need to know about you to confidently book their wedding vendor dream team.
          </p>
        </div>
      </div>
    </section>

    <mobile-apps />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CheckCircleIcon, GiftIcon, ActivityIcon, Edit2Icon, DollarSignIcon, LogInIcon, MailIcon, DatabaseIcon, ArrowUpRightIcon, SmileIcon } from 'vue-feather-icons'
import MobileApps from "@/components/landing/MobileApps";
import BenefitBox from "@/components/landing/BenefitBox";
import i18n from '@/i18n';

export default {
  components: {
    BenefitBox,
    MobileApps,
    CheckCircleIcon,
    GiftIcon,
    ActivityIcon,
    Edit2Icon,
    DollarSignIcon,
    LogInIcon,
    MailIcon,
    DatabaseIcon,
    ArrowUpRightIcon,
    SmileIcon,
  },
  layout: 'basic',
  // middleware: ['auth'],
  metaInfo() {
    return {
      title: i18n.t('navbar.vendors'),
      meta: [
        {
          name: 'description',
          content: i18n.t('description.vendors')
        }
      ]
    }
  },

  data: () => ({
    title: process.env.VUE_APP_TITLE,
    articles: [
      {
        src: 'https://picsum.photos/200/300?grayscale',
        title: 'A private account',
        text: 'Phasellus lorem enim, luctus ut velit eget, convallis egestas eros. Sed ornare ligula eget tortor tempor, quis porta tellus dictum.',
      },
      {
        src: 'https://picsum.photos/200/300?grayscale',
        title: 'Direct access to availability calendar',
        text: 'Nam ut leo ipsum. Maecenas pretium aliquam feugiat. Aenean vel tempor est, vitae tincidunt risus. Sed sodales vestibulum nibh.',
      },
      {
        src: 'https://picsum.photos/200/300?grayscale',
        title: 'Process invoices and payments via platform',
        text: 'Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
      },
      {
        src: 'https://picsum.photos/200/300?grayscale',
        title: 'Discover wedding vendors',
        text: 'Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
      },
      {
        src: 'https://picsum.photos/200/300?grayscale',
        title: 'Direct access to online booking',
        text: 'Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
      },
      {
        src: 'https://picsum.photos/200/300?grayscale',
        title: 'Reminder when payments are due',
        text: 'Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
      },
      {
        src: 'https://picsum.photos/200/300?grayscale',
        title: 'Direct access to vendor prices',
        text: 'Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
      },
      {
        src: 'https://picsum.photos/200/300?grayscale',
        title: 'Custom budget feature',
        text: 'Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
      },
      {
        src: 'https://picsum.photos/200/300?grayscale',
        title: 'All vendors and Payments in one place',
        text: 'Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.',
      },
    ],
    features: [
      {
        icon: 'mdi-account-group-outline',
        title: 'Manage your Rates and Availability',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam',
      },
      {
        icon: 'mdi-update',
        title: 'List Offerings',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam',
      },
      {
        icon: 'mdi-shield-outline',
        title: 'Stay up to date with Reservations',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam',
      },
      {
        icon: 'mdi-shield-outline',
        title: 'Process Invoices and Payments all in one place',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam',
      },
    ],
    stats: [
      ['24k', 'Vendors'],
      ['330+', 'Booking per day'],
      ['1m', 'Views'],
      ['5m', 'Comments'],
    ],
  }),
  mounted() {
    /// this.$http.get("sanctum/csrf-cookie");
  },
  computed: mapGetters({
    authenticated: 'auth/authenticated',
    user: 'auth/user',
    check: 'auth/check',

  })
}

</script>
