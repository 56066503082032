<template>
  <section class="tw-bg-red-100">
    <div class="tw-bg-red-100 tw-px-4 sm:tw-px-0 tw-col-span-1 tw-py-8 text-center">
      <div>
        <div>
          <h4 class="tw-text-black tw-text-2rem tw-leading-relaxed tw-font-semibold">
            {{ $t('general.download_vendor_app') }}
          </h4>
          <div class="tw-mt-8 tw-flex tw-space-x-4 tw-justify-center">
            <a href="https://apps.apple.com/us/app/mango-lola-for-vendors/id1600444629">
              <img src="@/assets/img/landing/app_store.svg" alt="">
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.mangololavendor">
              <img src="@/assets/img/landing/play_store.svg" alt="">
            </a>
          </div>
        </div>
        <div class="tw-mt-10 tw-hidden sm:tw-block" style="direction: rtl">
          <img class="sm:tw-max-w-md m-auto" src="@/assets/img/landing/vendor_app.png" alt="">
        </div>
      </div>
      <p class="tw-mt-10 sm:tw-m-0 tw-px-4">{{ $t('general.download_vendor_app_desc') }}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MobileApps',
}
</script>
